var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"detail"},[(
      _vm.getListCounties &&
        _vm.$route.meta.object !== 'sample' &&
        !_vm.$route.name.includes('Detail')
    )?_c('Counties',{attrs:{"counties":_vm.getListCounties}}):_vm._e(),(
      _vm.getListCounties &&
        _vm.$route.meta.object !== 'sample' &&
        !_vm.$route.name.includes('Detail')
    )?_c('v-divider'):_vm._e(),_c('router-view')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }