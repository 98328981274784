var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"counties",attrs:{"flat":"","tile":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t("counties.title"))+": ")]),_vm._l((_vm.counties),function(item,index){return _c('span',{key:item.id},[_c('router-link',{staticClass:"table-link",attrs:{"to":{
            query: {
              ..._vm.$route.query,
              maakond: item.maakond,
              page: 1,
              paginateBy: 500
            }
          },"exact-active-class":"font-weight-bold primary--text text--darken-1"}},[_vm._v(" "+_vm._s(_vm.$translate({ et: item.maakond, en: item.maakond_en }))+" ")]),(index !== _vm.counties.length - 1)?_c('span',[_vm._v("|")]):_vm._e()],1)})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }